.onoffswitch {
    position: relative; width: 42px;
    -webkit-user-select:none; 
    -moz-user-select:none; 
    -ms-user-select: none;
    user-select: none;
}
  
.onoffswitch-checkbox {
    position: absolute;
    opacity: 0;
    pointer-events: none;
}

.onoffswitch-label {
    display: block; overflow: hidden; cursor: pointer;
    height: 20px; padding: 0; line-height: 20px;
    border: 2px solid #E3E3E3; border-radius: 20px;
    background-color: #FFFFFF;
    transition: background-color 0.3s ease-in;
    width: inherit;
}
  
.onoffswitch-label:before {
    content: "";
    display: block; width: 20px; margin: 0px;
    background: #FFFFFF;
    position: absolute; top: 0; bottom: 0;
    right: 20px;
    border: 2px solid #E3E3E3; border-radius: 20px;
    transition: all 0.3s ease-in 0s; 
    height: 20px;
}
  
.onoffswitch-checkbox:checked + .onoffswitch-label {
    background-color: var(--orange);
}
  
.onoffswitch-checkbox:checked + .onoffswitch-label, .onoffswitch-checkbox:checked + .onoffswitch-label:before {
   border-color: var(--orange);
}

.onoffswitch-checkbox:checked + .onoffswitch-label:before {
    right: 0px; 
}

thead .onoffswitch{
    margin-right: 5px;
}

thead .onoffswitch label{
    align-items: center;
}