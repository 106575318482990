input { 
    padding: 0.5%;
    width: 100%;
    border-radius: 5px;
    border: 1px solid var(--primary-color); 
    
}

input:focus, select:focus { 
    border-color: var(--orange); 
}

input.error{
    border-color: var(--error-color);
}

/*buttons*/
input[type=button]{
    padding: 0.5%;
    border-radius: 17px;
    font-family: Roboto;
    font-size: 70%;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.436px;
    background-color: var(--primary-color); 
    color: var(--primary-button-color);
}

input[type=button]:hover{
    background-color:#000;
}

input[type=button].disabled,input[type=button].disabled:hover{
    background-color: #C1C1C2;
}

input.big {
    padding: 0.7%;
    border-radius: 24.941px;
    font-family: Roboto;
    font-weight: 600;
    letter-spacing: 0.436px;
    background: #444;
    color: var(--primary-button-color);
    height: 4vh;
}

label input[type=checkbox]{
    width: 18px;
    height: 18px;
    background-color: transparent;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid #302F2F;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
    position:relative;
}

select {
    border-radius: 5px;
    border-color: var(--primary-color);
    padding: 5px;
    width:100%;
}

.tab-pane select {
    width: fit-content;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {
  opacity: 1;
}

.MuiTableHead-root select{
    width: fit-content;
}

label [type=checkbox]:checked{
    background-color: var(--orange);
    border-color: var(--orange);
}

label [type=checkbox]:checked:after{
    border-color: #fff;
    border-style: solid;
    border-width: 0 3px 3px 0;
    box-sizing: border-box;
    content: '';
    aspect-ratio: 1.2 / 2.2;
    rotate: 40deg;
    display: inline-block;
    width: 6px;
    position: absolute;
    left: 5px;
    top: 2px;
}
  