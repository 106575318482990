.next-button {
    width: 57px;
    height: 25.69px;
    flex-shrink: 0; 
    border-radius: 17px;
    background: var(--orange); 
}

.next-button:disabled,.next-button[disabled]{
    background: #C1C1C2; 
}

.back-button {
    width: 57px;
    height: 25.69px;
    flex-shrink: 0; 
    border-radius: 17px;
    background: #000; 
}

.upload-button {
    width: 439.316px;
    height: 102.84px;
    flex-shrink: 0;
    border-radius: 19.969px;
    border: 0.998px dashed var(--orange);
    width: 244.619px;
    height: 44.93px;
    flex-shrink: 0;
}

.load-button {
    width: 84px;
    height: 37px;
    flex-shrink: 0;
    border-radius: 15.699px !important;
    border:none !important;
    background-color: var(--orange);
}

.replace-button {
    width: 57px;
    height: 19px;
    flex-shrink: 0;
    border-radius: 8.028px;
    background-color: #000 !important;
}

.run-button {
    width: 54px;
    height: 28px;
    flex-shrink: 0;
    border-radius: 17px !important;
    border:none !important;
    background: var(--orange) !important;
    margin-bottom: 0%;
}

.run-button:disabled {
    background: var(--medium-gray) !important;
}

.save-button {
    width: 49px;
    height: 24.055px;
    flex-shrink: 0;
    border-radius: 10.691px;
    background: #000;
}

.save-button:disabled,.save-button[disabled]{
    background: #C1C1C2; 
}

.export-button {
    width: 73px;
    height: 26px;
    flex-shrink: 0;
    border-radius: 17px;
    border:none !important;
    background-color: var(--orange);
}

.remove-button {
    width: 95.743px;
    height: 31.51px;
    flex-shrink: 0;
    border-radius: 20.603px;
    border:none !important;
    background-color: var(--orange);
}

.custom-checkbox {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .custom-checkbox input {
    display: none; 
  }
  
  .custom-checkbox span {
    width: 20px;
    height: 20px;
    background-color: white;
    border: 2px solid #000;
    border-radius: 5px; 
    display: inline-block;
    position: relative;
    margin-right: 10px;
    transition: background-color 0.2s ease;
  }
  
  .custom-checkbox input:checked + span {
    background-color: var(--orange); 
    border-color: var(--orange);
  }
  
  .custom-checkbox input:checked + span::after {
    content: "";
    position: absolute;
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  
