.tabsNavButton {
    padding: 15px 0;
    margin: 0 auto;
    text-align: right;
    width: 100%;
    align-self: flex-end;
  }
  
  .borderedDiv .tabsNavButton {
    padding-bottom: 0;
  }
  
  .tabsNavButton .nav-btn{
    border: none;
    display: inline-block;
    width: fit-content;
    background-color: #000;
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 13px;
    text-transform: capitalize;
  }
  
  .tabsNavButton .btn-next{
    background-color: var(--orange);
    margin-left: 20px;
  }
  
  .automatedCorrelation .btn-next{
    color: #000
  }
  