.projectPage {
    color: #FFF;
}

.projectPage .nav-color{
    background-color: #21272A !important;
}

.projectPage .selected-menu-item{
    background-color: #818181 !important;
}

.projectPage .project-item{
    border-bottom: 1px solid #818181;
}
.projectPage .project-item:hover{
    background-color: #818181 !important;
}
.about{
    margin-top: 30px;
    margin: 0 auto;
    max-width: 800px;
    padding: 20px;
    text-align: justify;
}

.trashProject{
    width: 3%;
}