.parserFields{
    width: 100%;
    display: grid;
    grid-template-columns: 400px 400px;    
  }
  
  .parserFields > div {
    display: inline-flex;    
    align-items: center;
    justify-content: space-between;
    margin: 0 20px 10px 0;
}
  
  .parserFields label{
    margin-right: 20px;
    width: 200px;
  }
  
  .parserFields select, .parserFields input {  
    background-color: transparent;
    border-color: #969696;
    min-width: 220px;
    width: 220px;
    min-height: 33px;
    padding-left: 5px;
    margin: 0;      
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image:
      linear-gradient(45deg, transparent 50%, gray 50%),
      linear-gradient(135deg, gray 50%, transparent 50%);    
    background-position:
      calc(100% - 20px) calc(0.9em),
      calc(100% - 15px) calc(0.9em);    
    background-size:
      5px 5px,
      5px 5px;    
    background-repeat: no-repeat;
  }

.parserFields input[type="number"] {
  background-image: none;
}
  
.parserFields select:focus, .parserFields input:focus {
  border-color: var(--orange);
  background-image:
    linear-gradient(45deg, var(--orange) 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, var(--orange) 50%);    
  background-position:
    calc(100% - 15px) calc(1em - 3px),
    calc(100% - 20px) calc(1em - 3px);    
  background-size:
    5px 5px,
    5px 5px;    
  background-repeat: no-repeat;  
  outline: 0;
}