@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import 'css/colors.css';
@import 'css/inputs.css';
@import 'css/sidebar.css';
@import 'css/scrollbars.css';
@import 'css/onoffswitch.css';
@import 'css/tabsNavButtons.css';
@import 'css/table.css';

html, body, #root, .page-container {
    height: 100%;
}

body{
    font-family: "Roboto";
    font-size: 100%;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 24px */  
    background-color: #DFDFDF;    
}

.page-container {
    display: flex;
    width: 100%;
}

.nav-tabs .nav-item .nav-link {
    background-color: #0080FF;
    color: #FFF;
}

.nav-tabs .nav-item .nav-link.active {
    color: #0080FF;
}


.main-col {
    transition: 500ms all;
    padding-left: 30px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-right: 30px !important;
    position: relative;
    background-color: var(--dark-white);
    display: flex;
    flex-direction: column;
}

.homeButton {
    display: inline-flex;
    justify-content: flex-end;    
    position: absolute;
    top: 30px;
    right: 35px;    
}

.homeButton .home-btn{
    border-radius: 10px;
    padding: 5px 10px;
    background-color: transparent;
    text-transform: capitalize;
}

.main-col h1 {    
    padding: 20px 0 0 0; 
    margin-bottom: 0;   
}

.main-col .selectData{
    font-size: 18px;
}

h1, 
.title {
    color: #000;
    font-size: 24px;    
    font-weight: 400;
    line-height: 100%; /* 24px */
}

h2, 
.title {
    color: #000;    
    font-size: 130%;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 24px */
}

h3, 
.subtitle {
    color: #000;    
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 20px */
}

h4, 
.topic {
    color: #000 !important;
    font-size: 110% !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 100% !important; /* 16px */
}

h5, 
.subtopic {
    color: rgba(0, 0, 0, 0.62);
    font-size: 80%;
    font-style: normal;
    font-weight: 400;
    line-height: 78%; /* 10.92px */
}

p:has(input:checked){
    opacity: 1;  
}

label { 
    display: inline-flex !important;
}

.main-col #main-tabs {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.main-col #main-tabs > .tab-content > .tab-pane{
    flex-direction: column;
}

.main-col #main-tabs > .tab-content > .tab-pane > section {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.main-col section {    
    overflow: auto;
    padding-top: 20px;
}

.main-col .nav-tabs{
    border-bottom: 2px solid black;
}

.main-col .nav-tabs .nav-link {
    display: inline-block;
    padding: 15px 25px;
    width: -moz-fit-content;
    width: fit-content;
    color: #000;
    margin-bottom: -2px;
    font-size: 16px;
    text-align: left;
    flex-grow: 0;
}

.main-col .nav-tabs .nav-link.active {
    background-color: transparent;
    border-bottom: 2px solid var(--orange);
    color:var(--orange);
    font-size: 16px;
}

.main-col .nav-tabs .nav-link.active:hover{
    border-bottom: 2px solid var(--orange);
}

.main-col .nav-tabs .nav-link:hover{
    border-color: transparent;
    cursor: pointer;
}

.main-col .tab-content {
    flex-grow: 1;
    display: flex;
    width: 100%;
}
 
.borderRounded .tab-content {
    padding: 0;
    margin-top: 0;
}

.borderRounded .tabsNavButton{
    padding: 0;
}

.borderRounded {
    width: 100%;
    border: 1px solid black;
    display: inline-flex;
    flex-direction: column;
    border-radius: 10px;
    flex-grow: 1;
    padding: 20px;
    margin-top: 20px;    
}

.borderedDiv {
    border: 1px solid black;  
    border-radius: 10px;
    flex-grow: 1;
    display: flex;  
    padding: 15px;
    overflow: auto;
  }
  
#Process .tab-content .tab-pane.active{
    align-content: center;
}

.main-col .tab-content .tab-pane {
    background-color: transparent;
    width: 100%;
}

.main-col .tab-content .tab-pane.active {
    display: flex;
    flex-direction: column;    
}

.main-col .k-tab {
    padding: 10px 20px;
    text-align: center;
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
    color: #000;
    flex-grow: 0;
    white-space: nowrap;
    width: 100%;
}

.main-col .k-tab.activeTab{
    color: var(--orange);
}

.main-col .process-nav{
    display: inline-flex;
    justify-content: center;
    margin: 0 0 20px 0;
}
    
.correctWellInfoAlert {
    text-align: center;
    width: 100%;
    font-weight: bold;
    font-size: 20px;  
    flex-grow: 1;
    align-content: flex-start;
    padding: 30px 10px;
}
  
    
*:focus {
    outline: none;
}

.associateLabel{    
    display: inline-flex;
    justify-content: space-between;
}

input.export-btn {
    width: fit-content;
    background-color: var(--orange) !important;
    padding: 5px 10px;
    text-transform: capitalize;
}

.correlationInfo {
    display: inline-flex;
    justify-content: space-between;
}

.correlationInfo span{
    width: 100%;
    display: inline-block;
}