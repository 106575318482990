.listFiles {
  width: 100%;
  max-width: 700px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 20px;
  overflow: auto;
  max-height: 375px;
}
  .listFiles label{
    padding: 20px 15px;
    width: 100%;
    border: 1px solid var(--medium-gray);
    margin-bottom: 8px;
    border-radius: 12px;
    align-items: center;
  }
  
  .listFiles label:last-child{
    margin-bottom: 0;
  }
  
  .listFiles label:hover, .wells label:hover{
    cursor: pointer;
  }

  .listFiles label:has(input[type=checkbox]:checked){
    background-color: var(--orange);  
  }