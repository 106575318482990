.activeTab{
    background-color: black;
    color: white;
    border-radius: 20px;
}

.arrow{
    font-size: 150%;
    color:darkgray;
    text-align: center;
    cursor: pointer;
    font-weight: bolder;
}

.leftArrow{
    text-align: left;
}

.k-tab{
    padding: 3px 0px;
    text-align: center;
    cursor: pointer;
    font-weight: bold;
    font-size: 110%;
}

.divRunCorrelation {
    display: inline-flex;
    width: 240px;
    flex-wrap: wrap;  
    justify-content: space-between;
}

.divRunCorrelation input[type="text"] {
    font-size: 12px;
    width: 70%;
    padding: 0 5px;
    background-color: transparent;
    border-color: var(--dark-gray);
}

.divRunCorrelation label {
    font-size: 14px;
    width: 100%;
}

.divRunCorrelation div div input[type="text"] {
    max-width: 200px;
    margin-right: 20px;
    width: 100%;
    font-size: 12px;
    height: 28px;
}

.correlationsWells, .correlationsProperties, .correlationGeologicalSettings, .correlationsOptions{
    width: 100%;
    flex-grow: 1;
}

.correlationsOptions > div{
    width: 100%;
    display: inline-flex;
}

.correlationLabel input[type="checkbox"]{
    margin-right: 5px;
}

section#automatedCorrelation {
    margin-top: 20px;
}

.correlationLabel{
    overflow-wrap: anywhere;  
}
  
.correlationsWells input[type="checkbox"] {
    margin-right: 5px;
}
  
  
.correlationsWells .onoffswitch {
    margin-right: 15px;
}

.divSlider{
    width: 100%;
    padding: 0 12px;
}

.MuiSlider-rail{
    color: #FFFFFF;
}

.MuiSlider-track{
    color: #000;
}

.MuiSlider-markLabel {
    font-size: 10px;
    color: #969696;
}

.saveTemplate {
    width: 300px;
    align-self: flex-end;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    margin-top: 20px;
}

.saveTemplate .field {
    width: 300px;
    align-self: flex-end;
    display: inline-flex;
    align-items: baseline;
    justify-content: center;
    margin-right: 15px;
}

.saveTemplate .field label{
    position: absolute;
    justify-content: center;
    top: -7px;
    padding: 0 6px;
    background-color: #DFDFDF;
    font-size: 14px;
}

.saveTemplate .field input{
    border-radius: 4px;
    padding: 10px;
    background-color: transparent;
    font-size: 14px
}


.selectToCorrelate {
    width: 100%;
    max-width: 300px;
}

.selectToCorrelate label{
    flex-direction: column;
    padding: 5px 0 0 0;
}

.selectToCorrelate span {
    display: inline-flex;
    width: 100%;
    align-items: baseline;
    margin-top: 10px;
    font-size: 14px;
}

.selectToCorrelate select, .geologicalSettingsOptions select, .geologicalSettingsOptions input {
    flex-grow: 1;
    background-color: transparent;
    border-radius: 3px;
    height: 33px;
    border-width: 2px;
    margin-left: 5px;
}