.fileUploader {
    border: 2px dashed var(--orange) !important;
    display: inline-flex !important;
    justify-content: center;
    padding: 80px !important;
    border-radius: 20px !important;
    width: 100%;
    max-width: 500px !important;
    margin: 0 auto;
    margin-top: 50px;
}

.fileUploader svg{
    display: none;
}

.fileUploader span{
    text-decoration: none !important;
    font-size: 18px !important;
    width: 100%;
}