/* Tabs*/
section {
    padding: 0px 0;
}

section .section-title {
    text-align: center;
    color: #1c1c24;
    margin-bottom: 50px;
    text-transform: uppercase;
}

#tabs .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #fff;            
    font-size: 20px;
    font-weight: bold;
}

#tabs .nav-tabs .nav-link {
    font-size: 20px;
    color: black;
    padding: 15px 0px;    
}
