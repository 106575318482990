.fieldset {
    height: 48px;
    border: 1px solid black;
    position: relative;
}

.legend {
    position: absolute;
    top: -40px;
    left: 20px;
    height: 20px;
    background-color: white;
    text-align: center;
}

.fieldset input{
    border: 1px solid white;
    width: 100%
}