.selectItems {
    display:inline-flex;
    width: 100%;
    height: 100%;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .selectItems .left, .selectItems .right {
    width: 44%;
    display: flex;
    flex-direction: column;
  }
  
  .selectItems .left > div, .selectItems .right > div {
    border: 1px solid #969696;
    border-radius: 10px;
    padding: 20px;
    flex-grow: 1;
    display: inline-flex;
    width: 100%;
    flex-direction: column;
  }
  
  .selectItems h3{
    text-align: left;
  }
  
  .selectItems .center {
    width: 16%;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 20px;
    align-items: center;
  }
  
  .selectItems .center button {
    margin: 10px;
    color: var(--orange);
    width: max-content;
    padding: 10px;
    background-color: transparent;
    font-size: 49px;
    border: 1px solid transparent;
    border-radius: 10px;
    letter-spacing: -5px;
    display: block;
    line-height: 1.1;
  }
  
  .selectItems .center button:hover{
    opacity: 0.5;
  }
  
  .selectItems select {
    width: 100% !important;
    border: 0;
    flex-grow: 1;
  }
  
  .selectItems select, .selectItems input {
    background-color: transparent;
  }

  .listItems p{
    opacity: 0.4;
  }
  
  .listItems .button{
    height: 25px;
    width: 25px;
    cursor: pointer;
  }
  
  .selectItems .filter-text{
    display:none;
  }
  