.table-container{
    width: 100%;
    flex-grow: 1;
    overflow: auto; 
    display: inline-flex; 
    margin-top: 20px;
  }
  
  table {
    width: 100%;
    background-color: white;
    border-radius: 10px;
    scroll-behavior: smooth;
    border-spacing: 10px 0;
  }
  
  table thead, table thead tr, table thead th, table thead select{
    background-color: #000;
    color: #fff !important;
  }
  
  .MuiTableRow-head th{
    line-height: initial;
  }
  
  .MuiTableRow-head th > div{
    display: inline-flex;
    width: 100%;
    margin-top: 5px;
  }
  
  table thead svg{  
    color: var(--orange);
  }
  
  
  /* table tbody tr:first-child{
    border-top: 60px solid #000;
  } */
  
  table td, table th {  
    padding: 10px !important;
    border-bottom: 0 !important;
  }
  
  table .firtsRowAfterStartline {
    border-top: 10px solid #DFDFDF;
    scroll-margin-top: 90px;
  }
  
  /* table tr:nth-child(even) {
    background-color: #ECECEC;
  } */
  
  table tr{
    border-bottom: 5px solid #dedede;  
  }
  
  table thead td:first-child{
    border-left: 0 !important;
  }
  
  /* table tbody td:first-child{
    border-left: 5px solid green !important;
  } */
  
  /* table tr.disabled:nth-child(even) {
    background-color: #f9f9f9;
  } */
  
  table tr.disabled{  
    border-left: 5px solid white;
    border-bottom: 1px solid #dedede;
  }
  
  table tr.disabled td{
    color: #dedede
  }
  
  thead tr:first-child {
    position: sticky;
    top: 0; 
    z-index: 1;   
  }
  
  .MuiTableContainer-root{
    overflow-y: visible !important;
    overflow-x: visible !important;
  }
  
  .headerBackgroundBlack{
    width: 100%;
    display: inline-block;
    position: absolute;
    background: black;
  }
  
  table.fixWellsInfoTable tbody tr:first-child {
    border-top: 0;
  }

  table.fixWellsInfoTable thead {
    background-color: black;
}