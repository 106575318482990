.geologicalSettingsOptions {
   display: inline-flex;
   justify-content: space-between;
 }
 
 .geologicalSettingsOptions .left, .geologicalSettingsOptions .right{
   width: 45%;
 }
 
 .geologicalSettingsOptions .left > div, .geologicalSettingsOptions .right > div {
   display: inline-flex;
   align-items: baseline;
   margin-bottom: 30px;
   flex-wrap: wrap;
   width: 100%;
 }
 
 .geologicalSettingsOptions .left > div {
   width: 100%;
   justify-content: flex-end;
 }
 
 .geologicalSettingsOptions .left label, .geologicalSettingsOptions .right label {
   margin-right: 20px;
 }
 
 .geologicalSettingsOptions .labelRadius{
   width: 100%;
 }

 .geologicalSettingsOptions select, .geologicalSettingsOptions input {
   width: 100px;
   margin-right: 5px;
   max-width: 150px;
 }

.radiusTooltip {
    position: relative;
    display: inline-block;    
}
    
.radiusTooltip .radiusTooltipText {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Position the radiusTooltip */
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
}

.radiusTooltip:hover .radiusTooltipText{
   visibility: visible;
}
    
.radiusTooltip .radiusTooltipText input{
   width: 50px;
   background-color: rgba(0,0,0,0);
   border:none;
   color:white;
   -moz-appearance:textfield;
   appearance: textfield;
}
    
.radiusTooltip .radiusTooltipText input:focus,
.radiusTooltip .radiusTooltipText input:hover{
   border: 1px black;
}
    
.radiusTooltip .radiusTooltipText input:focus,
.radiusTooltip .radiusTooltipText input::-webkit-outer-spin-button,
.radiusTooltip .radiusTooltipText input:focus,
.radiusTooltip .radiusTooltipText input::-webkit-inner-spin-button {
   /* display: none; <- Crashes Chrome on hover */
   -webkit-appearance: none;
   margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.boundariesTableDiv {   
   max-height: 400px;
   overflow: auto;
   margin: 0 auto;
   border-radius: 40px;
}
.boundariesTable{
   padding: 10%;
   border-radius: 40px;
   border:none !important;
}

.boundariesTable thead tr{
   border-radius: 40px 40px 0 0; 
}
.boundariesTable tbody tr:first-child,
.boundariesTable tbody:first-child{
   border-radius: 0 0 40px 40px; 
}
.boundariesTable thead tr th:first-child{
   border-radius: 40px 0 0 0;
}
.boundariesTable thead tr th:last-child{
   border-radius: 0 40px 0 0;
}
.boundariesTable tbody,
.boundariesTable tbody tr:first-child{
   border-radius: 0 0 40px 40px;
}
.boundariesTable tbody tr:last-child td:first-child{
   border-radius: 0 0 0 40px;
}
.boundariesTable tbody tr td svg{ 
   position: absolute;
   right: -8px;
   z-index: 501;
   color: var(--orange-border);
   bottom: -8px;
   background-color: white;
   border-radius: 50px;
   cursor: pointer;
}
.boundariesTable tbody tr td .tooltip-wrapper {
   position: absolute;
   right: 0px;
   bottom: 0px;
   z-index: 500;
   display: inline-block;
}

.boundariesTable tbody tr td .tooltip-icon {
   color: var(--orange-border);
   background-color: white;
   border-radius: 50px;
   cursor: pointer;
}

.boundariesTable tbody tr td .tooltip-text {
   display: none;
   position: absolute;
   top: -40px;
   left: 50%;
   transform: translateX(-50%);
   background-color: #ffffff;
   color: #0f0f0f;
   padding: 5px;
   border-radius: 3px;
   font-size: 12px;
   font-weight: bold;
   white-space: nowrap;
   z-index: 502;
   box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
   
}
.boundariesTable tbody tr td .tooltip-text::after {
   content: "";
   position: absolute;
   top: 100%; 
   left: 50%;
   transform: translateX(-50%);
   border-width: 5px;
   border-style: solid;
   border-color: #ffffff transparent transparent transparent; 
}

.boundariesTable tbody tr td .tooltip-wrapper:hover .tooltip-text {
   display: block;
}

.boundariesTable tbody tr td svg.removeRow {
   z-index: 99999999;
   top: 50%;
   margin-top: -8px;
   right: 100%;
   margin-right: -21px;
}


.boundariesTable tbody tr td svg path{
   color: var(--orange)
}

.boundariesTable tbody tr td svg.removeRow path{
   color: var(--red)
}

.boundariesTable tbody tr td{
   position:relative;
}
.boundariesTable thead tr th{
   border-top:0;
}
.boundariesTable thead tr{
   border-top:0;
}
.boundariesTable thead tr th:first-child{
   border-left:0;
}
.boundariesTable thead tr th:last-child{
   border-right:0;
}
.boundariesTable tbody tr:last-child td{
   border-bottom: 0;
}
.boundariesTable tbody tr:last-child td:first-child{
   border-left: 0;
}
.boundariesTable tbody tr:last-child td:last-child{
   border-right: 0;
}
.boundariesTable thead tr th:first-child,
.boundariesTable tbody tr td:first-child
{
   background-color: black;
   color:#fff;
   padding-left: 25px !important;
}
.boundariesTable thead tr th:not(first-child){
   background-color: #fff;
   color: #000 !important;
}

.boundariesTable td, .boundariesTable th{
   padding: 8px 4px;
   border: 1px solid darkgray;
}

.boundariesTable td select, .boundariesTable th select{
   width: 100%;
   border:none;
}

.radiusDiv {
   display: inline-flex;
   flex-grow: 0;
   margin-left: 30px;   
}

.radiusStyle {
   display: inline-flex;
   width: 100%;
   margin-top: 20px;
}

.radiusStyle input[type=range]{
   width: 50%;
}

.radiusDiv input {
   text-align: right;
   max-width: 90px;
   font-size: 12px;
   padding: 0 5px;
   border-radius: 10px;
   width: fit-content;
}

.MuiSlider-markLabel{
   font-size: 10px !important;
   color: #969696 !important;
}

span.MuiSlider-valueLabel {
   border-radius: 5px;
   color: #000;
   background-color: var(--orange);
   z-index: 9999999;
   padding: 5px;
}

/** Hiding arrows in Geological settings
/* Chrome, Safari, Edge, Opera */

/* .radiusDiv input::-webkit-outer-spin-button,
.radiusDiv input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
  */

/* Firefox */
/**
.radiusDiv input[type=number] {
  -moz-appearance: textfield;
} */