.correlationsOptions .fieldset {
    height: 48px;
    border: 0px solid lightgray;
    position: relative;
    display: inline-flex;
    width: 100%;
}

.correlationsOptions .legend {
    position: absolute;
    top: -7px;
    left: 20px;
    height: 20px;
    background-color: var(--dark-white);
    text-align: center;
    padding: 0 10px;
    font-size: 10px;
}

.correlationsOptions .fieldset input, .correlationsOptions .fieldset select {    
    width: 100%;
    background-color: transparent;
    padding: 0 10px;
    font-size: 12px;
    width: 100%;
    border-color: var(--dark-grey);
}

.correlationsOptions .numberCoresDiv{
    display: inline-flex;
    align-items: center;
}

.correlationsOptions .numberCoresDiv input{
    width: fit-content;
    margin: 0 10px;
}