:root {
  --dropzone-vertical-padding-left: 89px;
  --dropzone-vertical-padding-left-is-last: 45px;
  --dropzone-vertical-padding-left-external-drag: 33px;
  --dropzone-vertical-padding-left-external-first-drag: 35px;
  --dropzone-vertical-padding-left-active: 188px;
}

.KogChartsHeaderContainer {
  display: flex;
  margin-top: 30px;
  margin-bottom: 10px;
}

.dropZone {
  transition: 200ms all;
  min-height: 15px;    
  padding: 0;
  background-color: transparent;
}


.dropZone.active {  
  background-color: #b5f2b3;
}

.dropZone:nth-of-type(2n) {
  display: none;
}


.dropZone.horizontalDrag {
  width: 100%;  
  height: 10px;
  flex-grow: 1;  
}

.dropZone.horizontalDrag:first-child {
  margin-top: 0;
}

.dropZone.isLast.horizontalDrag {
  margin-bottom: 0;
}

.dropZone.horizontalDrag.active {
  min-height: 52px;
}

.dropZone.verticalDrag {
  height: auto;
  padding-left: var(--dropzone-vertical-padding-left);
}

.dropZone.verticalDrag.isLast {
  padding-left: var(--dropzone-vertical-padding-left-is-last);
}

.dropZone.verticalDrag.externalDrag {
  padding-left: var(--dropzone-vertical-padding-left-external-drag);
}

.dropZone.verticalDrag.externalDrag:first-child {
  padding-left: var(--dropzone-vertical-padding-left-external-first-drag);
}

.dropZone.verticalDrag.active {
  padding-left: var(--dropzone-vertical-padding-left-active);
}



/* .dropZone:not(.horizontalDrag).isLast {
  flex: 1 1 auto;
} */

.dropZone.active{  
  transition: 100ms all;
}

.wellGroup {
  padding: 0 0 10px 0px;
  flex-direction: column;
  display: flex;
  border: 1px solid #bbb;
  border-radius: 5px;
  cursor: move;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
}

.wellGroup h4 {
  width: 100%;
  padding: 0 5px;
  text-align: left;
  margin-top: -9px;
  display: block;
  width: max-content;
  background-color: #dfdfdf;
  margin-left: 10px;
  font-weight: bold !important;
}

.wellGroup .columns {
  display: flex;
  flex-grow: 1;
  padding: 0;
}

.wellGroup .propertyGroup {
  word-wrap: break-word;
  border: 1px solid #999;
  flex: 1 1 100%;
  background-color: white;
  cursor: move;
  width: 156px !important;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  align-content: center;
  flex-wrap: wrap;
  border-radius: 3px;
  box-shadow: 5px 5px 3px rgba(0, 0, 0, 0.25);
}


.wellGroup .propertyGroup .property {
  padding: 5px 0;
  font-size: 12px;
}

.wellGroup .columnContainer {
  display: flex;
}

span.spanProp {
  display: inline-block;
  width: 90%;
  padding: 0 5%;
  text-align: center;
}

span.spanMin, span.spanMax {
  display: inline-block;
  width: 44%;
  margin: 0 0.5%;
  text-align: center;
  font-size: 10px;
  border: 1px solid;
  border-radius: 3px;
}

