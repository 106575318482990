.styled-scrollbars{
    scroll-behavior: smooth;  
  }
  
  .styled-scrollbars::-webkit-scrollbar {
    position: absolute;
    /* Width of vertical scroll bar */
    width: 16px;
    /* Height of horizontal scroll bar */
    height: 16px;  
  }
  
  .styled-scrollbars::-webkit-scrollbar-thumb {
    border-radius: 9px;
    background: var(--orange);
    background-clip: padding-box;
    border-style: solid;
    border-color: transparent;
    border-right-width: 4px;
    border-left-width: 4px;
    border-top-width: 4px;
    border-bottom-width: 4px;
    position: relative  ;
  }
  
  .styled-scrollbars::-webkit-scrollbar-corner {  
    background: transparent;
  }