.sidebar-col{
  position: relative;
  transition: 500ms all;        
  display: flex;
  height: 100%;
}

.sidebar-col .sidebar-tabs{
  width: 100%;
}

.sidebar-toggle {
  position: absolute;
  top: 0;
  background-color: black;
  border: none;
  color: white;
  right: -15px;
  height: 100%;
  width: 15px;
  z-index: 1;
  border-radius: 0 5px 5px 0;
  padding: 0;
  transition: 500ms all;
}

.sidebar-toggle svg {
  transition: 500ms all;    
}

.sidebar-toggle.is-open {  
  top: calc(50% - 40px);
  height: 80px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.45);
}

.sidebar-toggle.is-open svg {  
  transform: rotate(180deg);
}

.sidebar-tab-content {
  border-radius: 0 10px 10px 0;
}


.sidebar-col nav{
  background-color: var(--dark-white);  
}

.sidebar-col .nav-tabs {
  border: 0;
  margin-top: 5px;
  margin-bottom: -7px;
}

.sidebar-col .nav-tabs a{
  padding-bottom: 24px !important;
}


.sidebar-col .nav-tabs .nav-link {
  padding: 5px;
  cursor: pointer;
  border: 0;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;    
  font-size: 20px;
  color: white !important;
  padding: 15px 0px;
  text-shadow: 3px 2px 5px rgba(0, 0, 0, 1);
}

.sidebar-col .nav-tabs .nav-link:first-child{
  border-bottom-left-radius: 0px;
}

.sidebar-col .nav-tabs .nav-link:last-child{
  border-bottom-right-radius: 0px;
}

.sidebar-col .nav-tabs .nav-link.active{
  background-color: black !important;
  color: white !important;
  border-bottom: 0 !important;  
  position: relative;
}

.sidebar-col .tab-content {
  height: calc(100% - 52px);
  padding: 15px;
  overflow: overlay;
  display: flex;
}

.sidebar-col .tab-content, .sidebar-col .tab-pane{
  background-color: black;
  color: white;
  border-bottom-right-radius:5px;
  width: 100%;      
}

.tab-content .tab-pane {  
  flex-wrap: wrap;
}

.sidebar-section {
  width: 100%;
  display: flex;
  margin-bottom: 15px;    
}

#Data .sidebar-section{
  max-height: 33%;
  overflow-y: auto;
}

.sidebar-section details {
  width: 100%;
}

.well-details > .filter-container > .react-checkbox-tree > ol > .rct-node-expanded > ol {  
  overflow-y: auto;
}

.tab-content > .active {
  display: flex;
  height: 100%;
  align-content: flex-start;
}

.sidebar-col summary {
  display: inline-flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;  
}

.sidebar-col summary.title{
  font-size: 20px;
  color: white;
  margin: 10px 0;
}

.sidebar-col details[open] summary.title{
  /* color:var(--orange);   */
}

.sidebar-col summary.subtitle, .sidebar-col div.subtitle {
  font-size: 16px;
  cursor: pointer;
}

.sidebar-col summary .arrow{
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 0px;  
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  animation-duration: 1s;
  width:10px;
  height: 10px;
  padding:5px;
  margin-right: 5px;
}

.sidebar-col details[open] summary .arrow{
  /* border-color: var(--orange); */
  transform: rotate(225deg);
  -webkit-transform: rotate(225deg);
  margin-top:8px;
}



.sidebar-col details div{
  background-color: #DFDFDF;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}


.sidebar-col details > div:last-child {
  padding-bottom: 0;
}

.sidebar-col details div.listItems {
  margin-bottom: 5px;
  max-height: 200px;
  overflow-y: auto;
  position: relative;
}

.sidebar-col .subtitle{
  padding: 10px !important;
}

.sidebar-col details[open] details[open] .subtitle{
  /* background-color: var(--orange);   */
}

.sidebar-col details[open] details[open]:first-child .subtitle{
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.sidebar-col details[open] ul,
.sidebar-col details[open] details[open] ul
 {
  margin: 10px 0;
  padding: 0 15px;
}

.sidebar-col details[open] ul li,
.sidebar-col details[open] details[open] ul li{
  list-style: none;  
  padding: 10px 0;
  border-left: 1px solid #c3bfbf;
  color: #969696;
  cursor: pointer;
}

.sidebar-col details[open] ul li:before,
.sidebar-col details[open] details[open] ul li:before {
  content: '';
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-left: -7.5px;
  margin-right: 10px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
  background-color: #C3BFBF;
}

.sidebar-col details[open] details[open] ul li.active, .sidebar-col details[open] div.active {  
  font-weight: normal;
  color: black;
}

.sidebar-col details[open] ul li.active:before, .sidebar-col details[open] details[open] ul li.active:before{
  background-color:black
}

.sidebar-col details[open] details[open] ul li.active:before {
  /* color: var(--orange); */
  font-weight: normal;
}

.sidebar-col .rct-title {
  display: flex;
  align-items: center;
}

.sidebar-col .rct-title:hover{
  background-color: transparent;
}

.sidebar-col .react-checkbox-tree label:hover {
  background-color: transparent;
}

.sidebar-col h3{
  font-weight: bold;
  font-size: 14px; 
  padding: 5px 15px;
  margin-top: 5px;
}

.sidebar-col section p {
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px;  
}

.sidebar-col section p:last-child {
  margin-bottom: 10px;
}

.sidebar-col section .listItems p{
  padding-right: 0;
}

.sidebar-col section p.displayFlex{
  display: flex;
  align-items: stretch;
}

.sidebar-col section p.margin-bottom-0{
  margin-bottom: 0;
}

.sidebar-col section p span {
  color: #000;
  font-size: 12px;
}

.sidebar-col section p.displayFlex span {
  flex-grow: 1;
  font-size: 11px;
  padding-right: 10px;
  align-items: center;
  display: inline-flex;
  justify-content: end;
}

.sidebar-col section p.displayFlex span.button{
  flex-grow: 0;
  padding-right: 0;
}

.sidebar-col section p span .roundedInput {
  width: fit-content;  
  border-radius: 5px;
  height: fit-content;
  display:inline-flex;
  align-items: stretch;
}

.sidebar-col section p span .roundedInput select, 
.sidebar-col section p span .roundedInput input, 
.sidebar-col section p span .roundedInput button {
  width: 50px;
  /* border: 1px; */
  min-height: 28px;
  text-align: center;
  max-height: -webkit-fill-available;
}

.sidebar-col section p span .roundedInput input[type=color]{
  height: 20px;
}

.sidebar-col section p span .roundedInput select{
  width: fit-content;
  min-width: 50px;
}

.sidebar-col section p span .roundedInput button {
  display: block;
  background-color: transparent;
}

.eye-checkbox{
  position: absolute;
  opacity: 0;
  width: fit-content;
}

.eye-label{
  width: 100%;
  height: 100%;
  cursor: pointer;  
}


.eye-label svg{
  width: 100%;
  height: 100%; 
  cursor: pointer; 
}


.workflows div.active span, .workflows .subtitle{
  color: black !important;
}

.workflows div span{
  color: #969696 !important;
}