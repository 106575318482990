.contextMenuOption:hover{
    background-color: #444;
    color:white;
}

.contextMenuOptionBlocked{
    color:#C1C1C2;
}
.contextMenuOptionBlocked:hover{
    color:#C1C1C2;
    background-color: var(--primary-color) !important;
}
.contextMenuOption{
    margin: 0 0.5rem; 
}
